<template>
  <div class="container mx-2">
    <!-- Breadcrumb  -->
    <va-card>
      <va-card-content>
        <va-breadcrumbs color="primary">
          <va-breadcrumbs-item
            :to="{ name: 'appeals' }"
            :label="$t('menu.appeals')"
          />
          <va-breadcrumbs-item :label="appeal.subject" />
        </va-breadcrumbs>
      </va-card-content>
    </va-card>

    <form class="mt-4" @submit.prevent="approve">
      <va-card gradient color="#f0f0f0">
        <va-card-content class="p-5" style="margin-bottom: 25px">
          <div class="row justify--space-between" style="padding: 25px">
            <h1 class="text-center" style="font-size: 2rem; display: inline">
              {{ appeal.subject }}
            </h1>

            <va-chip shadow flat>{{
              new Date(appeal.created).toLocaleString()
            }}</va-chip>
          </div>
          <h1 class="text-center" style="font-size: 1.5rem">
            <!-- {{ $t("by") }}: {{ championshipRequest.creator.name }} -->
            <hr />
          </h1>
          <div class="row md12 justify--space-between" style="padding: 25px">
            <div class="flex md12 mx-2">
              <va-input
                required
                class="gy-5 md12"
                v-model="commity1"
                :label="$t('commity1')"
                type="textarea"
              />
            </div>
            <div class="flex md12 mx-2">
              <va-input
                required
                class="gy-5"
                v-model="commity2"
                type="textarea"
                :label="$t('commity2')"
              />
            </div>
            <div class="flex md12 mx-2">
              <va-input
                required
                class="gy-5"
                v-model="commity3"
                type="textarea"
                :label="$t('commity3')"
              />
            </div>
            <div class="mt-2" style="line-height: 2.2">
              <h4>{{ $t("appeal_body") }}</h4>
              <p class="px-3">{{ appeal.body }}</p>
            </div>
          </div>

          <p style="margin-top: 5px !important" class="errors"></p>
        </va-card-content>
        <va-card-actions class="justify--space-between" align="right">
          <va-button
            type="submit"
            color="#039303"
            gradient
            :disabled="validate"
            :loading="loading"
          >
            {{ $t("approve") }}
            <span class="material-icons px-1"> task_alt </span>
          </va-button>
          <va-button @click="deny" color="#e42222">
            {{ $t("deny") }}
            <span class="material-icons px-1"> close </span>
          </va-button>
        </va-card-actions>
      </va-card>
    </form>
  </div>
</template>

<script>
import { request, gql } from "graphql-request";
const UPDATE_APPEAL = gql`
mutation ($id: Int, $status: Int, $commity1: String, $commity2: String, $commity3: String) {
  AppealsUpdate(appeals: {id: $id, status: $status, commity1: $commity1, commity2: $commity2, commity3: $commity3}) {
    id
  }
}
`;
export default {
  data() {
    return {
      appeal: {},
      commity1: "",
      commity2: "",
      commity3: "",
    };
  },
  async mounted() {
    const DETAILS = gql`
      query ($id: Int) {
        Appeals(id: $id) {
          team {
            name
            logo
          }
          subject
          body
          decision
          decision_time
          created
        }
      }
    `;
    const response = await request(this.$store.state.appUrl, DETAILS, {
      id: +this.$route.params.id,
    });
    this.appeal = response.Appeals[0];
  },
  methods: {
    async approve() {
      const response = await request(this.$store.state.appUrl, UPDATE_APPEAL, {
        id:+this.$route.params.id,
        commity1:this.commity1,
        commity2:this.commity2,
        commity3:this.commity3,
        status: 1,
      });
      if (response.AppealsUpdate.id) {
        this.$swal.fire({
          text: this.$t("approved_appeal_text"),
          icon: "success",
          confirmButtonColor: "#039303",
          confirmButtonText: this.$t("ok"),
        });

        this.$router.push({ name: "appeals" });
      }
    },
    async deny() {
      const response = await request(this.$store.state.appUrl, UPDATE_APPEAL, {
        id:+this.$route.params.id,
        commity1:this.commity1,
        commity2:this.commity2,
        commity3:this.commity3,
        status: 0,
      });
      if (response.AppealsUpdate.id) {
        this.$swal.fire({
          text: this.$t("denied_appeal_text"),
          icon: "error",
          confirmButtonColor: "#039303",
          confirmButtonText: this.$t("ok"),
        });

        this.$router.push({ name: "appeals" });
      }
    },
  },
};
</script>

<style>
.va-input__label {
  font-size: 12px !important;
  height: unset !important;
  margin-top: 2px;
}
.va-input--solid .va-input__container {
  border-color: #9a9a9a !important;
}
</style>
